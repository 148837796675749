import '@cloudscape-design/global-styles/index.css';

import { NotificationProvider } from '@risksmart-app/components/Notifications/NotificationProvider';
import { AxiosProvider } from '@risksmart-app/components/providers/AxiosProvider';
import { FC } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Outlet } from 'react-router';
import ApolloGraphqlProvider from 'src/data/ApolloGraphqlProvider';

import { AuthProvider } from './AuthProvider';
import ThirdPartyAuth0Context from './ThirdPartyAuth0Context';

const Providers: FC = () => {
  return (
    <HelmetProvider>
      <AuthProvider>
        <NotificationProvider>
          <ApolloGraphqlProvider>
            <AxiosProvider authContext={ThirdPartyAuth0Context}>
              <Outlet />
            </AxiosProvider>
          </ApolloGraphqlProvider>
        </NotificationProvider>
      </AuthProvider>
    </HelmetProvider>
  );
};

export default Providers;
